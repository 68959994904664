@import "variables";
@import "button";

:export {
  primary: $primary;
}

// span {
//   color: #909090;
// }

.cH1 {
  font-weight: bold;
  &--primary {
    color: $primary;
  }
  &--white {
    color: white;
  }
  &--pink {
    color: $linkColor;
  }
}

.cH2 {
  font-weight: bold;
  &--primary {
    color: $primary;
  }
  &--pink {
    color: $linkColor;
  }
}

.cH3 {
  font-weight: bold;
  &--pink {
    color: $linkColor;
  }
}

.cH4 {
  font-weight: bold;
  &--pink {
    color: $linkColor;
  }
}

.cH5 {
  font-weight: bold;
  &--white {
    color: white;
  }
  &--black {
    color: $black;
  }
  &--blue {
    color: $darkBlue;
  }
}

.link {
  cursor: pointer;
  &--pink {
    color: $linkColor !important;
  }
  &--underline {
    text-decoration: underline;
  }
}

.font-weight-bold {
  font-weight: bold;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
