// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

// @font-face {
//   font-family: "SK-Modernist";
//   src: local("Sk-Modernist"),
//     url("./assets/fonts/Sk-Modernist-Regular.otf") format("opentype");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "SK-Modernist";
//   src: local("Sk-Modernist"),
//     url("./assets/fonts/Sk-Modernist-Light.otf") format("otf");
//   font-weight: lighter;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "SK-Modernist";
//   src: local("Sk-Modernist"),
//     url("./assets/fonts/Sk-Modernist-Bold.otf") format("otf");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "SK-Modernist";
//   src: url("./assets/fonts/Sk-Modernist-Mono.otf") format("otf");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

html,
body {
  height: 100%;
  margin: 0;
  padding-right: 0 !important;
}
@font-face {
  font-family: "SK-Modernist";
  src: url("./assets/fonts/Sk-Modernist-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: "SK-Modernist";
  src: url("./assets/fonts/Sk-Modernist-Light.ttf") format("truetype");
  font-weight: lighter;
  font-display: swap;
}
@font-face {
  font-family: "SK-Modernist";
  src: url("./assets/fonts/Sk-Modernist-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "SK-Modernist";
//   src: url("./assets/fonts/Sk-Modernist-Mono.ttf") format("truetype");
//   font-weight: bold;
//   font-display: swap;
// }
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "SK-Modernist";
  font-weight: 400;
}
