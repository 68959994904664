@import "variables";

.themeButton {
  //   border-radius: 24px;
  padding: 10px 15px;
  font-weight: bold;
  border: 0;
  min-width: 120px;

  &:disabled {
    background: #dce0e0;
    pointer-events: none;
    color: #909090;
  }

  &--primary {
    background: $primary;
    color: black;

    &:hover {
      background: darken($primary, 10%);
      color: black;
    }
  }

  &--blue {
    background: $darkBlue;
    color: white;
    border: 1px solid $white;

    &:hover {
      background: darken($darkBlue, 10%);
      color: white;
      border: 1px solid $darkBlue;
    }
  }

  &--outline {
    background-color: white;
    color: $darkBlue;
    border: 1px solid $darkBlue;
    &:hover {
      background: darken(white, 10%);
      color: $darkBlue;
    }
  }

  &--secondary {
    color: $secondary;
    background: $white;
    border: 1px solid $secondary;

    &:hover {
      background: $secondary;
      color: $white;
      border: 1px solid $white;
    }
  }
  &--tertiary {
    color: $ashGray;
    background: $white;
    border: 1px solid $ashGray;

    &:hover {
      color: $ashGray;
      background: darken($white, 10%);
    }
  }

  &--myinfo {
    color: white;
    background: #f4333d;
    border: 1px solid $white;

    &:hover {
      background: darken(#f4333d, 10%);
      color: $white;
      border: 1px solid $white;
    }
  }

  &--cancel {
    color: $ashGray;
    background: #dddddd;
    border: 1px solid $white;

    &:hover {
      background: darken(#dddddd, 10%);
      color: $ashGray;
      border: 1px solid $white;
    }
  }

  &--upload {
    color: $black;
    background-color: #f5f5f5;
    border: 1px dashed #b2b2b2;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &:hover {
      background: darken(#f5f5f5, 10%);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
