@import "./scss/style.scss";

.leaflet-container {
  width: 100% !important;
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 0.7em;
  height: 1em;
}

::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 100vw;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #b3bacb;
  border-radius: 100vw;
}
