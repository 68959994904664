$theme: #011851;
$primary: #19f9b9;
$secondary: #3d58c3;
$white: #fff;
$info: #3d58c3;
$link: #20a8d8;
$black: #1e1e1e;

$linkColor: #fe2287;
$borderGrey: #e0e0e0;
$bgGrey: #f6f6f6;
$darkGray: #909090;
$bgDark: #01091e;
$darkBlue: #011851;
$ashGray: #1e1e1e;

$menuDarkBlue: #00113b;
$greyBlue: #b3bacb;
